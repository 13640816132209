import React, { useEffect, useState } from 'react';
import { useBrandSettings } from '../BrandContext';
import { AppointmentTab, AssessmentTab } from '../utils/constant';

interface FilterTagsProps {
  activeFilterTab: string;
  setActiveFilterTab: (tab: string) => void;
  resourceType?: string;
}

const FilterTags = (props: FilterTagsProps): JSX.Element => {
  const { activeFilterTab, setActiveFilterTab, resourceType } = props;
  const brandDetails = useBrandSettings();
  const [tabs, setTabs] = useState<{ value: string; label: string; }[]>([]); // Define the type of tabs as an array of objects

  useEffect(() => {
    const tabMap: Record<string, typeof AssessmentTab | typeof AppointmentTab> = {
      Questionnaire: AssessmentTab,
      Appointment: AppointmentTab,
    };
  
    setTabs(tabMap[resourceType || ''] || []);
  }, [resourceType]);

  return (
    <div className="tw-flex tw-justify-between tw-px-4">
      <div className="tw-inline-flex tw-rounded-md time-filter" role="group">
        {tabs?.map((tab, index) => (
          <div
            key={index}
            onClick={() => setActiveFilterTab(tab.value)}
            className={`tw-text-[white] tw-font-semibold tw-rounded-lg tw-text-sm tw-px-5 tw-py-2 tw-text-center tw-inline-flex tw-items-center tw-me-2 tw-mb-2 tw-border tw-border-[#D6BBFB] tw-w-max tw-cursor-pointer ${activeFilterTab === tab.value ? 'tw-bg-[#e1e3e796]' : 'tw-bg-white'}`}
            style={{
              color: activeFilterTab === tab.value ? brandDetails?.secondaryColor : '#667085',
              border: `1px solid ${activeFilterTab === tab.value ? brandDetails?.secondaryColor : '#667085'}`,
              backgroundColor: activeFilterTab === tab.value ? brandDetails?.backgroundColor : '#fff',
            }}
          >
            {tab.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterTags;