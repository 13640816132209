/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Appointment } from "@medplum/fhirtypes";


export const AppointmentResource = (formData: any, appointmentId: string, patient: any) => {
    const appointment: Appointment = {
        resourceType: 'Appointment',
        status: `${formData?.practitioner ? 'booked' : 'pending'}`,
        start: formData?.slotStartDate,
        end: formData?.slotEndDate,
        participant: [
            {
                actor: {
                    reference: `HealthcareService/${formData?.service}`,
                    display: formData?.serviceName
                },
                status: 'accepted'
            },
            {
                actor: {
                    reference: `Parctitioner/${formData?.provider}`,
                    display: formData?.providerName
                },
                status: 'accepted'
            },
            {
                actor: {
                    reference: `Patient/${patient?.id}`,
                    display: patient?.name[0]?.text
                },
                status: 'accepted'
            }
        ],
        slot: [
            {
                reference: `Slot/${formData?.slots}`
            }
        ],
        appointmentType: {
            coding: [
                {
                    system: 'http://terminology.hl7.org/CodeSystem/v2-0276',
                    code: formData?.appointmentType,
                    display: formData?.appointmentType
                }
            ]
        },
        description: formData.note
    };
    if(formData?.practitioner) {
        appointment.participant.push({
            actor: {
                reference: `Practitioner/${formData?.practitioner}`,
                display: formData?.practitionerName
            },
            status: 'accepted'
        });
    }
    appointment.id = appointmentId;
    return appointment;
}