import { Container, Text, Divider, Group, Button, PasswordInput, Notification } from '@mantine/core';
import { useBrandSettings } from '../../BrandContext';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changePatientPassword } from '../../utils/CustomAPI';
import { useMedplum } from '@medplum/react';
import { showNotification } from '@mantine/notifications';

export const ChangePassword: React.FC = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const brandDetails = useBrandSettings();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    password: '',
    newPassword: '',
    confirmPassword: '',
  });

  const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z0-9!@#$%^&*(),.?":{}|<>]{8,}$/;
  const ERROR_MESSAGES = {
    requiredFields: 'All fields must be filled.',
    passwordMismatch: 'New password and confirm password do not match.',
    invalidPassword: 'Password must be 8+ chars with a capital letter, a number, and a special character.',
    unknownError: 'An unknown error occurred.',
    genericError: 'Something went wrong. Please try again.',
  };

  const validatePasswordAndUpdate = async () => {
    const { password, newPassword, confirmPassword } = formData;

    // Validation
    if (!password || !newPassword || !confirmPassword) {
      return setErrorMessage(ERROR_MESSAGES.requiredFields);
    }
    if (newPassword !== confirmPassword) {
      return setErrorMessage(ERROR_MESSAGES.passwordMismatch);
    }
    if (!PASSWORD_REGEX.test(newPassword)) {
      return setErrorMessage(ERROR_MESSAGES.invalidPassword);
    }

    setErrorMessage(null);

    const payload = { oldPassword: password, newPassword, confirmPassword };

    try {
      const userResponse = await changePatientPassword(medplum, payload);

      if (userResponse?.id === 'ok') {
        showNotification({
          message: 'Password updated successfully!',
          color: 'green',
        });
        return navigate(`/dashboard`);
      }

      console.log('User response:', userResponse);
    } catch (error: any) {
      const errorDetails =
        error.response?.data?.issue?.find((issue: any) => issue?.details?.text)?.details?.text ||
        ERROR_MESSAGES.unknownError;

      setErrorMessage(error.response?.status === 400 ? errorDetails : ERROR_MESSAGES.genericError);

      console.error('Unexpected error:', error);
    }
  };

  const handleConfirmPasswordChange = (value: string) => {
    setFormData((prev) => {
      const updatedFormData = { ...prev, confirmPassword: value };
      if (
        !updatedFormData.password ||
        !updatedFormData.newPassword ||
        !updatedFormData.confirmPassword ||
        updatedFormData.newPassword !== updatedFormData.confirmPassword
      ) {
        setErrorMessage('Confirm password does not match the new password.');
      } else {
        setErrorMessage(null);
      }
      return updatedFormData;
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    validatePasswordAndUpdate();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container className="tw-mt-5 tw-ml-5">
        <Text className="tw-text-[#101828] tw-font-semibold tw-text-xl tw-font-['Inter']">Change Password</Text>
        <Text className="tw-text-[#475467] tw-text-sm font-normal tw-mt-1">Update your password here</Text>
        <Divider className="tw-mt-5 tw-flex-2" />
        <div className="tw-flex tw-mt-10">
          <Text className="tw-flex-shrink-0 tw-mr-1 tw-text-[#344054] tw-font-medium tw-text-[14px] tw-font-['Inter'] tw-w-40">
            Current Password
          </Text>
          <PasswordInput
            name="password"
            autoComplete="off"
            placeholder="Current Password"
            required={true}
            className="tw-mb-1 tw-ml-16 tw-w-[400px] tw-bg-white  tw-rounded"
            onChange={(e) => setFormData({ ...formData, password: e.currentTarget.value })}
          />
        </div>
        <div className="tw-flex tw-mt-5">
          <Text className="tw-flex-shrink-0 tw-mr-1 tw-text-[#344054] tw-font-medium tw-text-[14px] tw-font-['Inter'] tw-w-40">
            New Password
          </Text>
          <PasswordInput
            name="new_password"
            autoComplete="off"
            placeholder="New Password"
            required={true}
            value={formData.newPassword}
            onChange={(e) => setFormData({ ...formData, newPassword: e.currentTarget.value })}
            className="tw-mb-1 tw-ml-16 tw-w-[400px] tw-bg-white  tw-rounded"
          />
        </div>
        <div className="tw-flex tw-mt-5">
          <Text className="tw-flex-shrink-0 tw-mr-1 tw-text-[#344054] tw-font-medium tw-text-[14px] tw-font-['Inter'] tw-w-40">
            Confirm New Password
          </Text>
          <PasswordInput
            name="confirm_password"
            autoComplete="off"
            placeholder="Confirm New Password"
            required={true}
            className="tw-mb-1 tw-ml-16 tw-w-[400px] tw-bg-white  tw-rounded"
            value={formData.confirmPassword}
            onChange={(e) => handleConfirmPasswordChange(e.currentTarget.value)}
          />
        </div>
        {errorMessage && (
          <Notification color="red" onClose={() => setErrorMessage(null)} className="tw-mt-4">
            {errorMessage}
          </Notification>
        )}
        <Divider className="tw-mt-8 tw-flex-2" />
        <Group align="right" className="tw-flex tw-justify-end tw-mt-8">
          <Button
            variant="outline"
            color="gray"
            onClick={() => navigate(`/dashboard`)}
            className="tw-mr-2 tw-text-[#344054] tw-font-medium tw-text-sm tw-font-['Inter'] tw-rounded-lg"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className={`tw-bg-[${brandDetails?.backgroundColor}] tw-text-white tw-font-medium tw-text-sm tw-font-['Inter'] tw-rounded-lg hover:bg-[${brandDetails?.backgroundColor}]`}
            style={{ backgroundColor: brandDetails?.backgroundColor }}
          >
            Update
          </Button>
        </Group>
      </Container>
    </form>
  );
};
