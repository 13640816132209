/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { useBrandSettings } from '../../../app/src/BrandContext';

interface GroupButtonsProps {
  value: { id: number, name: string, icon?: React.ReactNode }[];
  buttonHandleClick: (data: { id: number, name: string }) => void;
}

const GroupButtons = (props: GroupButtonsProps) => {
  const brandDetails = useBrandSettings();
  const [btnActive, setBtnActive] = React.useState<number>(props?.value?.[0].id);
  const handleClick = (data: { id: number, name: string }) => {
    setBtnActive(data.id);
    props.buttonHandleClick(data);
  }
  return (
    <>
      <div className="tw-inline-flex tw-rounded-md time-filter" role="group">
        {props?.value.map((data) => (
          <button
            key={data.id}
            type="button"
            className={`tw-text-[white] tw-font-semibold tw-rounded-lg tw-text-sm tw-px-5 tw-py-2 tw-text-center tw-inline-flex tw-items-center tw-me-2 tw-mb-2 tw-border tw-border-[#D6BBFB] tw-w-max ${btnActive === data.id ? 'tw-bg-[#e1e3e796]' : 'tw-bg-white'}`}
            onClick={() => handleClick(data)}
            style={{
              color: btnActive === data.id ? brandDetails?.secondaryColor : '#667085',
              border: `1px solid ${btnActive === data.id ? brandDetails?.secondaryColor : '#667085'}`,
              backgroundColor: btnActive === data.id ? brandDetails?.backgroundColor : '#fff',
            }}
          >
            {data.icon && <span className="tw-mr-2">{data.icon}</span>}
            {data.name}
          </button>
        ))}
      </div>
    </>
  )
}

export default GroupButtons;
