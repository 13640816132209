/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMedplum } from '@medplum/react';
import React, { useEffect, useState } from 'react';
import { generatePatientHealthSummary } from '../../utils/CustomAPI';
import { IconRefresh } from '@tabler/icons-react';
import { useBrandSettings } from '../../BrandContext';
import { useAppContext } from '../../AppProvider';

const PatientHealthSummary = () => {
  const medplum = useMedplum();
  const  { isCheckPageRefresh } = useAppContext()
  const brandDetails = useBrandSettings();
  const [patientHealthSummary, setPatientHealthSummary] = useState<any>(null);
  const [authoredDate, setAuthoredDate] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    getPatientHealthSummary();
  }, []);

  // for api call when condition updated
  useEffect(() => {
    if (isCheckPageRefresh) {
      getPatientHealthSummary();
    }
  }, [isCheckPageRefresh]);

  const getPatientHealthSummary = async (refreshSummary: boolean = false) => {
    setIsLoading(true);
    try {
      const response = await generatePatientHealthSummary(medplum, medplum.getProfile()?.id || '', refreshSummary);
      const data = response?.data;
      const attachmentData = JSON.parse(data?.content?.[0]?.attachment?.data || '{}');
      setPatientHealthSummary(attachmentData);
      setAuthoredDate(data?.date || '');
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = (content: any, level = 1) => {
    return Object.keys(content).map((key, index) => {
      const value = content[key];
      const isObject = typeof value === 'object' && value !== null;
      const isArray = Array.isArray(value);
      const marginLeft = level * 3;
  
      return (
        <div key={key} style={{ marginBottom: '20px', marginLeft: `${marginLeft}px` }}>
          <span style={{ fontWeight: 700, fontSize: '14px', color: '#333' }}>
            {level === 1 && !isArray ? `${index + 1}. ` : '\u2022 '}
            {key.replace(/([A-Z])/g, ' $1').trim()}:
          </span>
          {isObject ? (
            <div style={{ margin: '15px' }}>
              {isArray ? (
                value.map((item: any, idx: number) => (
                  <div key={idx} style={{ marginBottom: '10px' }}>
                    {typeof item === 'object' ? (
                      renderContent(item, level + 1)
                    ) : (
                      <span
                        style={{
                          fontSize: '14px',
                          marginLeft: `${level * 3}px`,
                          fontWeight: 500,
                          color: '#475467',
                          lineHeight: '26px',
                        }}
                      >
                        {'\u2022 '} {item}
                      </span>
                    )}
                  </div>
                ))
              ) : (
                renderContent(value, level + 1)
              )}
            </div>
          ) : (
            <span
              style={{
                fontSize: '14px',
                margin: '10px 5px',
                fontWeight: 500,
                color: '#475467',
                lineHeight: '26px',
              }}
            >
              {value}
            </span>
          )}
        </div>
      );
    });
  };

  const renderSection = (title: string, content: any, index: number) => (
    <>
      <h5 style={{ fontWeight: 700, fontSize: '14px', color: '#333', marginBottom: '15px' }}>
        {`${index + 1}. ${title}`}
      </h5>
      {renderContent(content, 2)}
    </>
  );

  return (
    <>
      <div className='tw-bg-[#fff] tw-px-3 tw-pt-5 tw-rounded-b-lg tw-text-[#101828] tw-text-[18px] tw-font-semibold tw-border tw-border-[#EAECF0]'>
        {patientHealthSummary && typeof patientHealthSummary === 'object' && Object.keys(patientHealthSummary).length > 0 && (
          <div className='tw-px-3' style={{ borderBottom: '1px solid rgb(234, 236, 240)', paddingBottom: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <div>
              <h5 style={{ fontSize: '12px', fontWeight: 400 }}>Last Generated</h5>
              <p style={{ fontSize: '12px', fontWeight: 600 }}>{new Date(authoredDate).toLocaleDateString()}</p>
            </div>
            <button
              type="button"
              className="tw-text-[white] tw-font-semibold tw-rounded-lg tw-text-sm tw-px-5 tw-py-2 tw-ml-2 tw-text-center tw-inline-flex tw-items-center tw-me-2 tw-mb-2 tw-border tw-border-[#D6BBFB] tw-w-max"
              onClick={() => getPatientHealthSummary(true)}
              style={{
                color: brandDetails?.secondaryColor,
                border: `1px solid ${brandDetails?.secondaryColor}`,
                backgroundColor: brandDetails?.backgroundColor,
              }}
            >
              <IconRefresh style={{ marginRight: '5px' }} />
              Refresh
            </button>
          </div>
        )}
        {isLoading ? (
          <span className="loading">
            <p className='tw-text[14px] tw-text-[#475467]'>Loading</p>
            <span className="loading__dot"></span>
            <span className="loading__dot"></span>
            <span className="loading__dot"></span>
          </span>
        ) : patientHealthSummary && typeof patientHealthSummary === 'object' && Object.keys(patientHealthSummary).length > 0 ? (
          <div className="tab-content assessment_insights_tab scrollable_div" style={{ height: '400px', overflowY: 'scroll', marginTop: '10px', marginBottom: '10px' }}>
            {Object.keys(patientHealthSummary).map((key, index) => (
              <div key={key}>
                {renderSection(key.replace(/([A-Z])/g, ' $1').trim(), patientHealthSummary[key], index)}
              </div>
            ))}
          </div>
        ) : (
          <p style={{ fontSize: '14px', fontWeight: 500, marginBottom: '8px' }}>No AI Health Summary Found.</p>
        )}
      </div>
    </>
  );
};

export default PatientHealthSummary;