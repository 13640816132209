/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import LoginWithSSO from './LoginWithSSO';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from '../utils/axios';
import { LoginAuthenticationResponse } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { Loader, Skeleton } from '@mantine/core';
import { useBrandSettings } from '../BrandContext';
import SignInCarousel from './SignInCarousel';

const Login = () => {
  const medplum = useMedplum();
  const brandDetails = useBrandSettings();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const config = JSON.parse(localStorage.getItem('config') || '{}');
  const clientId = localStorage.getItem('clientId');
  const [startLogin, setStartLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  const getAccessToken = async () => {
    setIsLoading(true);
    try {
      let formbody: any = {};
      const uri = window.location.href.split('?')[0];
      formbody = {
        code: code,
        redirectUrls: uri,
        tokenEndpoint: config?.apiUrl,
        clientId: clientId,
        grant_type: 'authorization_code',
        client: config?.ehr,
      };
      if (formbody.tokenEndpoint) {
        const res = await axios.post(`/api/sso/get-ehr-token-patient`, formbody);
        localStorage.setItem('ehr_token', JSON.stringify(res));
        localStorage.setItem('patient_id', res.data.patient);
        const { email, password } = res.data;
        //const clientId = config?.clientId;
        medplum.startLogin({ email, password, patient: true }).then(handleAuthResponse).catch(console.error);
      }
    } catch (error) {
      console.error('Error getting access token:', error);
    }
  };

  function handleAuthResponse(response: LoginAuthenticationResponse): void {
    if (response.code) {
      handleCode(response.code);
    }
    if (response.memberships) {
      medplum
        .post('auth/profile', {
          login: response.login,
          profile: response.memberships[0].id,
        })
        .then(handleAuthResponse)
        .catch(console.error);
    }
  }

  function handleCode(code: string): void {
    medplum
      .processCode(code)
      .then((response: any) => {
        console.log('Code processed:', response);
        navigate('/Dashboard');
      })
      .catch(console.error);
  }

  useEffect(() => {
    if (brandDetails && Object.keys(brandDetails).length > 0) {
      setLoading(false);
    }
  }, [brandDetails]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        if (code && !startLogin) {
          if (isMounted) {
            setStartLogin(true);
          }
          await getAccessToken();
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!startLogin) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [code, state]);

  return (
    <>
      {isLoading && (
        <div className="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-black tw-opacity-50 tw-z-50">
          <Loader color="blue" className="tw-block tw-m-auto tw-relative tw-top-[50%]" />
        </div>
      )}
      <div className="tw-flex tw-min-h-screen tw-sm:block">
        <div className="tw-flex-initial tw-w-1/2 banner-bg-2 tw-block tw-m-auto">
          <div className="tw-flex tw-mb-5">
            <div className="tw-w-[26%] tw-mb-4"></div>
            {loading ? (
              <Skeleton height={40} width={170} />
            ) : (
              <img
                src={brandDetails?.brandLogo || '../img/Pragma_logo_Purple.svg'}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.onerror = null;
                  target.src = '../img/Pragma_logo_Purple.svg';
                }}
                className="tw-w-[180px] tw-block"
                alt="pragmaconnect Logo"
              />
            )}
          </div>
          {!code && <LoginWithSSO />}
        </div>
        <div className="tw-w-1/2 tw-flex tw-items-center tw-justify-center tw-p-4 tw-relative">
          <SignInCarousel />
        </div>
      </div>
    </>
  );
};

export default Login;
