/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMedplum } from '@medplum/react';
import React, { useEffect, useState } from 'react';
import { assessmentInsightsSummary } from '../../utils/CustomAPI';
import { formatDate } from '@medplum/core';
import Assessment from '../../components/Assessment';
import { useAppContext } from '../../AppProvider';

const AssessmentInsights = () => {
  const medplum = useMedplum();
  const  { isCheckPageRefresh } = useAppContext();
  const [assessmentCompletionDate, setAssessmentCompletionDate] = useState('');
  const [assessmentInsightsSummaryData, setAssessmentInsightsSummaryData] = useState<string[]>([]);
  const [hasData, setHasData] = useState<boolean>(true);
  useEffect(() => {
    getAssessmentInsightsSummary();
  }, []);

  // for api call when condition updated
  useEffect(() => {
    if (isCheckPageRefresh) {
      getAssessmentInsightsSummary();
    }
  }, [isCheckPageRefresh]);

  const getAssessmentInsightsSummary = () => {
    assessmentInsightsSummary(medplum, medplum.getProfile()?.id || '')
      .then((res) => {
        setAssessmentCompletionDate(formatDate(res?.data?.authored));
        const insights = res?.data.extension.find((data: any) => data.url === 'http://ai-insights');
        if (insights) {
          const lines = insights.valueString.split('\n');
          setAssessmentInsightsSummaryData(lines);
          setHasData(true);
        } else {
          setHasData(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setHasData(false);
      });
  };

  return (
    <>
      <div className='tw-bg-[#fff] tw-px-3 tw-pt-5 tw-rounded-b-lg tw-text-[#101828] tw-text-[18px] tw-font-semibold tw-border tw-border-[#EAECF0]'>
        <Assessment
          assessmentCompletionDate={assessmentCompletionDate}
          assessmentInsightsSummaryData={assessmentInsightsSummaryData}
          hasData={hasData} 
        />
      </div>
    </>
  );
};

export default AssessmentInsights;