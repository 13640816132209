/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMedplum } from '@medplum/react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';

const Tabs = (props: any) => {
  const medplum = useMedplum();
  const { id } = useParams();
  const patientId = id || '';
  const [resourceLength, setResourceLength] = useState([]);
  const [hovered, setHovered] = useState(false);
  const tabsContainerRef = useRef<HTMLUListElement>(null);

  const tabs = useMemo(
    () => [
      { id: 1, name: 'Basic details' },
      { id: 2, name: 'Observations' },
      { id: 3, name: 'Immunizations' },
      { id: 4, name: 'Reports' },
      { id: 5, name: 'Appointments' },
      { id: 6, name: 'Insurance' },
      { id: 7, name: 'Procedures' },
      { id: 8, name: 'Medications' },
      { id: 9, name: 'Assessments' },
    ],
    []
  );

  const onTabClick = useCallback(
    (e: any) => {
      props.setActiveTab(e.id);
    },
    [props.setActiveTab]
  );

  const handleNext = () => {
    if (tabsContainerRef.current) {
      tabsContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const handlePrevious = () => {
    if (tabsContainerRef.current) {
      tabsContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    medplum
      .searchResources('Appointment', {
        patient: `Patient/${patientId}`,
      })
      .then((res: any) => {
        setResourceLength(res);
      })
      .catch((err) => {
        console.error(err);
        setResourceLength([]);
      });
  }, [props.activeTab, patientId]);

  return (
    <div
      className="tw-tabs tw-w-full tab-list tw-relative"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered && (
        <button
          className="tw-absolute tw-left-2 tw-top-[9px] tw-bg-white tw-p-1 tw-rounded-full tw-shadow-md tw-border tw-border-gray-300 tw-z-10 tw-transition tw-duration-300 tw-ease-in-out"
          onClick={handlePrevious}
        >
          <IconChevronLeft size={24} color="black" />
        </button>
      )}
      <ul
        ref={tabsContainerRef}
        className="tw-flex tw-items-center tw-bg-[#F9FAFB] tw-rounded-[10px] tw-py-[4px] tw-px-[6px] tw-overflow-hidden tw-scroll-smooth tw-border tw-border-[#EAECF0] tw-w-full"
      >
        {tabs.map((tab) => (
          <li key={tab.id}>
            <a
              href="#"
              className={`tw-inline-block tab tw-py-2 tw-px-4 tw-text-[#697586] tw-text-[14px] tw-font-semibold tw-tablink tw-whitespace-nowrap ${
                props.activeTab === tab.id ? 'active' : ''
              }`}
              onClick={() => onTabClick(tab)}
            >
              <div className="tw-flex tw-items-center">
                {tab.name}
                {tab.id === 5 && (
                  <span className="tw-ml-2 tw-border tw-border-[#EAECF0] tw-bg-[#F9FAFB] tw-text-[#344054] tw-font-medium tw-py-[2px] tw-px-2 tw-rounded-full">
                    {resourceLength?.length}
                  </span>
                )}
              </div>
            </a>
          </li>
        ))}
      </ul>
      {hovered && (
        <button
          className="tw-absolute tw-right-2 tw-top-[9px] tw-bg-white tw-p-1 tw-rounded-full tw-shadow-md"
          onClick={handleNext}
        >
          <IconChevronRight size={24} />
        </button>
      )}
    </div>
  );
};

export default Tabs;
