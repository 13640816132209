import { Group, AppShell as MantineAppShell, Menu, UnstyledButton, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import cx from 'clsx';
import { useState } from 'react';
import classes from '../../../react/src/AppShell/Header.module.css';
import { HeaderDropdown } from '../../../react/src/AppShell/HeaderDropdown';
import { HeaderSearchInput } from '../../../react/src/AppShell/HeaderSearchInput';
import { ResourceAvatar, useMedplumProfile } from '@medplum/react';
import { formatHumanName, capitalize } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';

export interface HeaderProps {
  readonly pathname?: string;
  readonly searchParams?: URLSearchParams;
}

export function Header(props: HeaderProps): JSX.Element {
  const profile = useMedplumProfile();
  const [userMenuOpened, setUserMenuOpened] = useState(false);

  return (
    <MantineAppShell.Header p={8} mt={10} style={{ border: 'none', position: 'relative', paddingLeft: '0' }} className='cust_header'>
      <Group justify="space-between">
        <Group gap={40}>
          <HeaderSearchInput pathname={props.pathname} searchParams={props.searchParams} />
        </Group>
        <Group gap="lg" pr="sm">
          <Menu
            width={260}
            shadow="xl"
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            opened={userMenuOpened}
            onClose={() => setUserMenuOpened(false)}
          >
            <Menu.Target>
              <UnstyledButton
                className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                onClick={() => setUserMenuOpened((o) => !o)}
              >
                <Group gap={7}>
                  <ResourceAvatar value={profile} radius="xl" size={24} />
                  <Text size="sm" className={classes.userName}>
                    {capitalize(profile?.name?.[0].given?.[0] ?? '') + ' ' + capitalize(profile?.name?.[0].family ?? '')}
                  </Text>
                  <IconChevronDown size={12} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <HeaderDropdown version='' />
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </MantineAppShell.Header>
  );
}
