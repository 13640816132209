export interface LogoProps {
  readonly size: number;
  readonly fill?: string;
}

export function Logo(props: LogoProps): JSX.Element {
  return (
    <img src="../img/pragmaconnect-logo.svg" style={{width: props.size}} alt='pragmaconnect Logo' />
  );
}
