/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Drawer, Grid, Text, Select, Textarea, Loader} from '@mantine/core';
import { IconCalendarMonth, IconChevronDown } from '@tabler/icons-react';
import { DateInput } from '@mantine/dates';
import { useBrandSettings } from '../../BrandContext';
import { useMedplum } from '@medplum/react';
import { useForm } from '@mantine/form';
import { AppointmentResource } from './AppointmentResource';
import Slots from '../../components/Slots';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';

interface AppointmentsProps {
  opened: boolean;
  close: () => void;
  appointmentId: string;
}

interface HealthcareService {
  id: string;
  name: string;
}

const Appointments = (props: AppointmentsProps) => {
  const medplum = useMedplum();
  const form = useForm();
  const navigate = useNavigate();
  const brandDetails = useBrandSettings();
  const [healthcareService, setHealthcareService] = useState<HealthcareService[]>([]);
  const [provider, setProvider] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getService();
    getProvider();
  }, []);

  const getService = () => {
    medplum.search('HealthcareService').then((response) => {
      setHealthcareService(response?.entry?.map((entry: any) => {
        return {
          id: entry.resource.id,
          name: entry.resource.name
        }
      }) ?? []);
    }).catch((error) => {
      console.error(error);
    });
  }

  const getProvider = () => {
    medplum.search('Practitioner').then((response) => {
      setProvider(response?.entry?.map((entry: any) => {
        return {
          id: entry.resource.id,
          name: entry.resource.name?.[0]?.family + ' ' + entry.resource.name?.[0]?.given?.[0]
        }
      }) ?? []);
    }).catch((error) => {
      console.error(error);
    });
  }
  const onSelectSlot = (slot: any) => {
    form.setFieldValue('slots', slot.id);
    form.setFieldValue('slotStartDate', slot.startTime);
    form.setFieldValue('slotEndDate', slot.endTime);
  }

  const bookAppointment = (values: any) => {
    if(!values.slots) {
      setError(false);
      showNotification({ color: 'red', message: 'Please select a slot' });
      return;
    }
    setLoading(true);
    const appointment = AppointmentResource(values, props.appointmentId, medplum.getProfile());
    medplum.createResource(appointment).then(() => {
      showNotification({ color: 'green', message: 'Appointment updated successfully' });
      props.close();
      setLoading(false);
      navigate('/Appointment');
    }).catch((error) => {
        console.log('error', error);
        showNotification({ color: 'red', message: 'Failed to update appointment' });
        setLoading(false);
    });
  }
  //console.log(form.values);
  return (
    <>
      <Drawer offset={8} position="right" size="md" radius="md" opened={props.opened} onClose={props.close} title={<span className='tw-font-semibold tw-text-[16px]'>Book Appointment</span>} className='appointment'>
        <hr  className='tw-border-[#EEF1F6]'/>
        <form onSubmit={form.onSubmit((values) => bookAppointment(values))}>
          <div className='tw-mt-5'>
            <Grid className='tw-mb-3'>
              <Grid.Col span={3}>
                <Text className='tw-text-[#000] tw-font-medium'>Service</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <Select
                  placeholder="Select Service"
                  name='service'
                  data={healthcareService.map((service) => ({ value: service.id, label: service.name }))}
                  rightSection={<IconChevronDown color='#667085'/>}
                  onChange={(event, e) => {
                    form.setFieldValue('service', event);
                    form.setFieldValue('serviceName', e.label);
                  }}
                />
              </Grid.Col>
            </Grid>
            <Grid className='tw-mb-3'>
              <Grid.Col span={3}>
                <Text className='tw-text-[#000] tw-font-medium'>Provider</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <Select
                  placeholder="Select Provider"
                  data={provider.map((provider) => ({ value: provider.id, label: provider.name }))}
                  rightSection={<IconChevronDown color='#667085'/>}
                  name='provider'
                  onChange={(event, e) => {
                    form.setFieldValue('provider', event);
                    form.setFieldValue('providerName', e.label);
                  }}
                />
              </Grid.Col>
            </Grid>
            <Grid className='tw-mb-3'>
              <Grid.Col span={3}>
                <Text className='tw-text-[#000] tw-font-medium'>Appt. Type</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <Select
                  placeholder="Select Appointment Type"
                  data={['Followup', 'Initial Consultation']}
                  rightSection={<IconChevronDown color='#667085'/>}
                  name='appointmentType'
                  {...form.getInputProps('appointmentType')}
                />
              </Grid.Col>
            </Grid>
            <Grid className='tw-mb-3'>
              <Grid.Col span={3}>
                <Text className='tw-text-[#000] tw-font-medium'>Date</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <DateInput
                  placeholder="Date"
                  valueFormat="YYYY-MM-DD"
                  name="startDate"
                  rightSection={<IconCalendarMonth size="16px" />}
                  {...form.getInputProps('startDate')}
                />
              </Grid.Col>
            </Grid>
            <Grid className='tw-mb-3'>
              <Grid.Col span={12}> 
                <Slots onSelectSlot={onSelectSlot} serviceId={form?.values?.service} startDate={form?.values?.startDate} slotError={error}/>
              </Grid.Col>
            </Grid>
            <Grid className='tw-mb-3'>
              <Grid.Col span={3}>
                <Text className='tw-text-[#000] tw-font-medium'>Note</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <Textarea
                  placeholder="Note..."
                  name='note'
                  {...form.getInputProps('note')}
                />
              </Grid.Col>
            </Grid>
          </div>
          <hr  className='tw-border-[#EEF1F6]'/>
          <Grid>
            <Grid.Col span={6}>
              <button className='tw-w-full tw-py-3 tw-bg-[#F9FAFB] tw-text-[#475467] tw-font-medium tw-rounded-[8px] tw-mt-5 tw-border tw-border-[#F2F2F2]'
              onClick={props.close}>Cancel</button>
            </Grid.Col>
            <Grid.Col span={6}>
              <button className='tw-w-full tw-py-3 tw-bg-[#3B82F6] tw-text-white tw-font-medium tw-rounded-[4px] tw-mt-5' style={{ backgroundColor: brandDetails?.buttonColor}}
              disabled={loading}>Book Appointment {loading && (<Loader pl={10} pt={3} size={15} color='white'/>)}</button>
            </Grid.Col>
          </Grid>
        </form>
      </Drawer>
    </>
  )
}

export default Appointments;
