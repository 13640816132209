/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Patient } from '@medplum/fhirtypes';
import React from 'react';

interface BasicDetailsProps {
  patientResource:  Patient | undefined;
}

const BasicDetails: React.FC<BasicDetailsProps> = (props: BasicDetailsProps) => {
  const { patientResource } = props;
  const DetailItem = ({ title, value }: any) => (
    <div className='tw-flex tw-items-center tw-p-4 tw-justify-between'>
      <div className='tw-w-1/2'>
        <p className='tw-text-[#121926] tw-font-semibold tw-text-[14px]'>{title}</p>
      </div>
      <div className='tw-w-1/2'>
        <p className='tw-text-[#4B5565] tw-font-medium tw-text-[14px]'>{value}</p>
      </div>
    </div>
  );
  return (
    <>
      <div
          className="tw-mt-6 tw-block tw-pb-4 tw-bg-white tw-border border-[#F2F2F2] tw-rounded-md"
          >
            <div className='tw-bg-[#F8FAFC] tw-p-4 tw-rounded-e-md tw-rounded-s-md'>
              <h5 className="tw-text-[14px] tw-font-semibold tw-text-[#344054]">
                General information
              </h5>
            </div>
            <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <DetailItem title="Name" value={`${patientResource?.name?.[0].given?.[0]} ${patientResource?.name?.[0].family}`} />
            <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <DetailItem title="Birth Date" value={patientResource?.birthDate} />
            <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <DetailItem title="Gender" value={patientResource?.gender} />
        </div>
    </>
  )
}

export default BasicDetails
