import React from 'react';

const SearchAndFilter = ( props: any): JSX.Element => {
  const { searchQuery, setSearchQuery } = props;
  return (
    <div className="tw-flex tw-justify-end tw-mb-4 tw-w-full">
      <div className="tw-relative tw-flex tw-justify-center tw-mr-2 ">
        <span className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
          <svg className="tw-w-4 tw-h-4 tw-text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </span>
        <input
          type="search"
          className="tw-pl-10 tw-pr-6 tw-py-3 tw-w-full tw-text-sm tw-text-gray-700 tw-bg-transparent tw-border tw-rounded-lg tw-border-gray-300 tw-outline-none focus:tw-border-gray-400"
          placeholder={'Search '+location.pathname.split('/')[1].toLocaleLowerCase()}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
    </div>
  );
};

export default SearchAndFilter;
