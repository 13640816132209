/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useMemo, useState } from 'react';
import Header from './Header';
import Tabs from './Tabs';
import PatientBasicDetails from './PatientDetails.tsx';
import { PatientInfo } from './PatientDetails.tsx/PatientInfo';
import { useMedplum } from '@medplum/react';
import { useMedplumProfile } from '@medplum/react-hooks';

const Patient = () => {
  const [activeTab, setActiveTab] = React.useState(1);
  const medplum = useMedplum();
  const profile = useMedplumProfile();
  const id = profile?.id;
  const patientId = id || '';
  const [resourceData, setResourceData] = useState<any[]>([]);
  const [patientResource, setPatientResource] = useState<any>();
  const [activeInssuranceFilterTab, setActiveInssuranceFilterTab] = useState<string>('Coverage');
  const [isLoading, setIsloading] = useState<boolean>(false);

  const getResourceType = () => {
    const resourceType: Record<number, string> = {
      2: 'Observation',
      3: 'Immunization',
      4: 'DiagnosticReport',
      5: 'Appointment',
      6: `${activeInssuranceFilterTab}`,
      7: 'Procedure',
      8: 'MedicationRequest',
      9: 'QuestionnaireResponse',
    };

    return resourceType[activeTab];
  };

  useEffect(() => {
    if (activeTab !== 1) {
      const resourceType = getResourceType();
      if (resourceType === 'QuestionnaireResponse') {
        // First, fetch QuestionnaireResponses for the patient
        medplum
          .searchResources('QuestionnaireResponse', {
            patient: `Patient/${patientId}`,
          })
          .then(async (responses: any[]) => {
            // Now, fetch the associated Questionnaires
            const questionnairePromises = responses.map((response) => {
              // console.log(response.questionnaire);

              if (response?.questionnaire) {
                return medplum.readReference({reference: response.questionnaire, type: 'Questionnaire'}).catch((error) => {
                  // Handle the error
                  console.error(error);
                  return null;
                });
              } else {
                return Promise.resolve(null);
              }
            });

            // console.log(questionnairePromises);
            
            const questionnaires = await Promise.all(questionnairePromises);

            // // Combine QuestionnaireResponses with their Questionnaires
            const combinedData = responses.map((response, index) => ({
              response,
              questionnaire: questionnaires[index],
            }));

            setResourceData(combinedData);
          })
          .catch((err) => {
            console.error(err);
            setResourceData([]);
          });
      } else {
        // Handle other resource types as before
        medplum
          .searchResources(
            resourceType as
              | 'Observation'
              | 'Immunization'
              | 'DiagnosticReport'
              | 'Coverage'
              | 'Claim'
              | 'Appointment'
              | 'Procedure'
              | 'MedicationRequest',
            {
              patient: `Patient/${patientId}`,
            }
          )
          .then((res: any) => {
            setResourceData(res);
            setIsloading(false);
          })
          .catch((err) => {
            console.error(err);
            setResourceData([]);
          });
      }
    } else {
      setResourceData([]);
    }
  }, [activeTab, patientId, activeInssuranceFilterTab]);
  console.log(resourceData)

  useEffect(() => {
    if (patientId) {
      medplum
        .readResource('Patient', patientId)
        .then((res: any) => {
          setPatientResource(res);
          setActiveTab(1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [patientId]);

  const memoizedPatientResource = useMemo(() => patientResource, [patientResource]);
  const memoizedPatientBasicDetails = useMemo(
    () => <PatientBasicDetails patientResource={memoizedPatientResource} patientId={patientId} />,
    [memoizedPatientResource, patientId]
  );

  const memoizedPatientInfo = useMemo(
    () => (
      <PatientInfo
        activeTab={activeTab}
        resourceData={resourceData}
        activeInssuranceFilterTab={activeInssuranceFilterTab}
        setActiveInssuranceFilterTab={setActiveInssuranceFilterTab}
        isLoading={isLoading}
      />
    ),
    [activeTab, resourceData, activeInssuranceFilterTab]
  );

  return (
    <div className="tw-py-5 tw-px-8">
      <Header patientResource={patientResource} />
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === 1 ? memoizedPatientBasicDetails : memoizedPatientInfo}
    </div>
  );
};

export default Patient;
