/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { getSlots } from '../utils/CustomAPI';
import { useMedplum } from '@medplum/react';
import { Grid } from '@mantine/core';
import { useBrandSettings } from '../BrandContext';

interface SlotsProps {
    onSelectSlot: (slot: any) => void;
    serviceId: string;
    startDate: string;
    slotError: boolean;
}

interface Slot {
    id: string;
    time: string;
    startTime: string;
    endTime: string;
}

const Slots: React.FC<SlotsProps> = (props: SlotsProps) => {
    const brandDetails = useBrandSettings();
    const [activeSlot, setActiveSlot] = useState<string>('')
    const medplum = useMedplum();
    const [slots, setSlots] = useState<Slot[]>([]);
    const [isSlotsEmpty, setIsSlotsEmpty] = useState<boolean>(false);
    const onClickSlot = (slot: Slot) => {
        setActiveSlot(slot?.id);
        props.onSelectSlot(slot);
    }

    useEffect(() => {
        if(props.serviceId && props.startDate) {
            getSlotsList();
        }
    }, [props.serviceId, props.startDate]);

    const formatDate = (date: string | number | Date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    
    const getSlotsList = () => {

        getSlots(medplum, props.serviceId, formatDate(props.startDate)).then((res) => {
            if(res.data.entry.length === 0) {
                setIsSlotsEmpty(true);
                setSlots([]);
            } else {
                setIsSlotsEmpty(false);
                setSlots(res.data.entry.map((slot: any) => {
                return {
                    id: slot.resource.id,
                    time: new Date(slot.resource.start).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                    startTime: slot.resource.start,
                    endTime: slot.resource.end
                }
                }))
            }
        }).catch((err) => {
            console.log(err);
        });
    }
  return (
    <>
      {slots.length > 0 && (
        <Grid>
            <Grid.Col span={3}>
                <label className='tw-text-[#000] tw-font-semibold'>Slots</label>
            </Grid.Col>
            <Grid.Col span={9}>
                <div className='slots_container'>
                    {slots.map((slot) => {
                        const isActiveSlot = activeSlot === slot.id;
                        const slotStyle = {
                            backgroundColor: isActiveSlot ? brandDetails?.buttonColor : 'white',
                            color: isActiveSlot ? 'white' : '#333',
                            cursor: 'pointer'
                        };
                        return (
                            <div key={slot.id} onClick={() => onClickSlot(slot)} className='slots_btn tw-w-[30%' style={slotStyle}>
                                <span>{slot.time}</span>
                            </div>
                        );
                    })}
                    {props?.slotError && (<span className='tw-font-medium tw-text-[red]'>Please select slot</span>)}
                </div>
            </Grid.Col>
        </Grid>
      )} 
      {isSlotsEmpty && (
        <>
            <label className='tw-text-[#475467] tw-font-semibold'>Slots</label>
            <div className='tw-text-center tw-font-medium tw-mt-10 tw-mb-10 tw-text-[18px]'>No slots available.</div>
        </>
      )}
    </>
  )
}

export default Slots
