import { Anchor, Button, Checkbox, Divider, Group, PasswordInput, Skeleton, Stack, TextInput } from '@mantine/core';
import {
  BaseLoginRequest,
  GoogleCredentialResponse,
  GoogleLoginRequest,
  LoginAuthenticationResponse,
  normalizeOperationOutcome,
} from '@medplum/core';
import { OperationOutcome } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react-hooks';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Form } from '../Form/Form';
import { GoogleButton } from '../GoogleButton/GoogleButton';
import { getGoogleClientId } from '../GoogleButton/GoogleButton.utils';
import { OperationOutcomeAlert } from '../OperationOutcomeAlert/OperationOutcomeAlert';
import { getErrorsForInput, getIssuesForExpression } from '../utils/outcomes';
import { useBrandSettings } from '../../../app/src/BrandContext';
import { useNavigate } from 'react-router-dom';
import { RegistrationStepper } from './RegistrationStepper';

export interface AuthenticationFormProps extends BaseLoginRequest {
  readonly disableEmailAuth?: boolean;
  readonly disableGoogleAuth?: boolean;
  readonly onForgotPassword?: () => void;
  readonly onRegister?: () => void;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  readonly isRegister: boolean;
  readonly setIsRegister: (isRegister: boolean) => void;
  readonly children?: ReactNode;
}

export function AuthenticationForm(props: AuthenticationFormProps): JSX.Element {
  const [email, setEmail] = useState<string>();

  const handleRegisterClick = () => {
    props.setIsRegister(true);
  };

  if (props.isRegister) {
    return <RegistrationStepper onLoginClick={() => props.setIsRegister(false)} />;
  }

  if (!email) {
    return <EmailForm setEmail={setEmail} onRegister={handleRegisterClick} {...props} />;
  } else {
    return <PasswordForm email={email} {...props} />;
  }
}

export interface EmailFormProps extends BaseLoginRequest {
  readonly disableEmailAuth?: boolean;
  readonly disableGoogleAuth?: boolean;
  readonly onRegister?: () => void;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  readonly setEmail: (email: string) => void;
  readonly children?: ReactNode;
  readonly onForgotPassword?: () => void;
}

export function EmailForm(props: EmailFormProps): JSX.Element {
  const { handleAuthResponse, onForgotPassword, onRegister, children, disableEmailAuth, ...baseLoginRequest } = props;
  const medplum = useMedplum();
  const navigate = useNavigate();
  const brandDetails = useBrandSettings();
  const googleClientId = !props.disableGoogleAuth && getGoogleClientId(props?.googleClientId);
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const issues = getIssuesForExpression(outcome, undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (brandDetails && Object.keys(brandDetails).length > 0) {
      setLoading(false);
    }
  }, [brandDetails]);

  const isExternalAuth = useCallback(
    async (authMethod: any): Promise<boolean> => {
      if (!authMethod.authorizeUrl) {
        return false;
      }

      const state = JSON.stringify({
        ...(await medplum.ensureCodeChallenge(baseLoginRequest)),
        domain: authMethod.domain,
      });
      const url = new URL(authMethod.authorizeUrl);
      url.searchParams.set('state', state);
      window.location.assign(url.toString());
      return true;
    },
    [medplum, baseLoginRequest]
  );

  const handleSubmit = useCallback(
    (formData: Record<string, string>) => {
      medplum
        .startLogin({
          ...baseLoginRequest,
          password: formData.password,
          remember: formData.remember === 'on',
          email: formData.email,
          patient: true,
        })
        .then(handleAuthResponse)
        .catch((err: unknown) => setOutcome(normalizeOperationOutcome(err)));
    },
    [medplum, baseLoginRequest, handleAuthResponse]
  );

  const handleGoogleCredential = useCallback(
    async (response: GoogleCredentialResponse) => {
      try {
        const authResponse = await medplum.startGoogleLogin({
          ...baseLoginRequest,
          googleCredential: response.credential,
        } as GoogleLoginRequest);
        if (!(await isExternalAuth(authResponse))) {
          handleAuthResponse(authResponse);
        }
      } catch (err) {
        setOutcome(normalizeOperationOutcome(err));
      }
    },
    [medplum, baseLoginRequest, isExternalAuth, handleAuthResponse]
  );
  if (loading) {
    return (
      <div>
        <Skeleton height={40} mb="sm" />
        <Skeleton height={40} mb="sm" />
        <Skeleton height={40} mb="sm" />
        <Skeleton height={50} />
      </div>
    );
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h1 style={{ flexDirection: 'column', textAlign: 'left', marginTop: '30px' }}>{children}</h1>
        <OperationOutcomeAlert issues={issues} />
        {loading ? (
          <Skeleton height={50} width={180} />
        ) : (
          <h4 style={{ textAlign: 'left', color: '#475467', marginTop: '5px' }}>
            Welcome back! Please enter your details.
          </h4>
        )}
        {googleClientId && (
          <>
            <Group justify="center" p="xl" style={{ height: 70 }}>
              <GoogleButton googleClientId={googleClientId} handleGoogleCredential={handleGoogleCredential} />
            </Group>
            {!disableEmailAuth && <Divider label="or" labelPosition="center" my="lg" />}
          </>
        )}
        {!disableEmailAuth && (
          <div>
            <TextInput
              name="email"
              type="email"
              label="Email or username"
              placeholder="Enter your Email Id"
              required={true}
              autoFocus={true}
              error={getErrorsForInput(outcome, 'email')}
              style={{ margin: '25px 0 20px' }}
            />
            <Stack gap="xl">
              <PasswordInput
                name="password"
                label="Password"
                autoComplete="off"
                placeholder="Enter your Password"
                required={true}
                autoFocus={true}
                error={getErrorsForInput(outcome, 'password')}
              />
            </Stack>
          </div>
        )}
        {/* <Group justify="space-between" mt="md" className='d-flex' gap={0} wrap="nowrap">
          <Checkbox id="remember" name="remember" label="Remember me" size="xs" style={{ lineHeight: 1, fontWeight: '600', color: '#000' }} />
          {onForgotPassword && (
              <Anchor component="button" style={{ fontWeight: '400', color: brandDetails?.textColor || '#0000', fontSize: '15px'}} type="button" onClick={onForgotPassword} size="xs">
                Forgot password?
              </Anchor>
          )}
        </Group> */}
        <div style={{ marginTop: '20px' }}>
          <Button type="submit" className="w-100 sign-btn" style={{ backgroundColor: brandDetails?.buttonColor }}>
            Sign in
          </Button>
        </div>
        <Divider my="xs" label="OR" labelPosition="center" />
        <div className="tw-text-center tw-mt-[20px]">
          <Button
            type="button"
            className="w-100 sm:tw-w-[100%] sign-btn"
            onClick={() => navigate('/login')}
            style={{ backgroundColor: `${brandDetails?.buttonColor || '#9552E8'}` }}
          >
            Sign in with EHR SSO
          </Button>
        </div>
      </Form>
      <div className="tw-text-center tw-mt-[20px]">
        Don't have an account?
        <Anchor
          component="button"
          style={{
            fontWeight: '400',
            color: brandDetails?.textColor || '#9552E8',
            fontSize: '15px',
            marginLeft: '5px',
          }}
          onClick={onRegister}
        >
          Register
        </Anchor>
      </div>
    </>
  );
}

export interface PasswordFormProps extends BaseLoginRequest {
  readonly email: string;
  readonly onForgotPassword?: () => void;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  readonly children?: ReactNode;
}

export function PasswordForm(props: PasswordFormProps): JSX.Element {
  const { onForgotPassword, handleAuthResponse, ...baseLoginRequest } = props;
  const medplum = useMedplum();
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const issues = getIssuesForExpression(outcome, undefined);

  const handleSubmit = useCallback(
    (formData: Record<string, string>) => {
      medplum
        .startLogin({
          ...baseLoginRequest,
          password: formData.password,
          remember: formData.remember === 'on',
        })
        .then(handleAuthResponse)
        .catch((err: unknown) => setOutcome(normalizeOperationOutcome(err)));
    },
    [medplum, baseLoginRequest, handleAuthResponse]
  );

  return (
    <Form onSubmit={handleSubmit}>
      {/* <Center style={{ flexDirection: 'column' }}>{children}</Center> */}
      <OperationOutcomeAlert issues={issues} />
      <Stack gap="xl">
        <PasswordInput
          name="password"
          label="Password"
          autoComplete="off"
          placeholder="Enter your Password"
          required={true}
          autoFocus={true}
          error={getErrorsForInput(outcome, 'password')}
        />
      </Stack>
      <Group justify="space-between" className="d-flex" gap={0} wrap="nowrap">
        <Checkbox id="remember" name="remember" label="Remember me" size="xs" />
        {onForgotPassword && (
          <Anchor
            component="button"
            style={{ fontWeight: '600', color: '#475467', fontSize: '13px' }}
            type="button"
            onClick={onForgotPassword}
            size="xs"
          >
            Forgot password
          </Anchor>
        )}
      </Group>
      <div style={{ marginTop: '20px' }}>
        <Button type="submit" className="w-100 sign-btn">
          Sign in
        </Button>
      </div>
    </Form>
  );
}
