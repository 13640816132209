/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Patient } from '@medplum/fhirtypes';

export const createPatient = (formData: any, patientId: string | undefined) => {
  const Patient: Patient = {
    resourceType: 'Patient',
    name: [
      {
        family: formData.lastName,
        given: [formData.firstName],
      },
    ],
    birthDate: formData.dob,
    gender: formData.gender,
    active: true,
    telecom: [
      {
        system: 'phone',
        value: formData.mobile,
      },
      {
        system: 'email',
        value: formData.email,
      },
    ],
    address:
      formData.street && formData.city && formData.state && formData.postalCode
        ? [
            {
              line: [formData.street],
              city: formData.city || '',
              state: formData.state || '',
              postalCode: formData.postalCode || '',
            },
          ]
        : [],
    managingOrganization: {
      reference: `Organization/${formData.providerGroup}`,
      display: formData.providerGroup,
    },
    generalPractitioner: [
      {
        reference: `Practitioner/${formData.provider}`,
        display: formData.provider,
      },
    ],
  };
  if (patientId) {
    Patient.id = patientId;
  }
  return Patient;
};
