import React, { useEffect, useState } from 'react';
import { Modal, Text, Box, Loader } from '@mantine/core';
import { getAssessmentList } from '../../utils/CustomAPI';
import { useMedplum } from '@medplum/react';
import { useBrandSettings } from '../../BrandContext';

interface AssessmentDetailsProps {
  isOpen: boolean;
  handleClose: () => void;
  assessmentDetails: {
    id: string;
    'Assessment Name': string;
    'Completion Date': string;
    Status: string;
  };
}

const AssessmentDetailsModel: React.FC<AssessmentDetailsProps> = ({ isOpen, handleClose, assessmentDetails }) => {
  const medplum = useMedplum();
  const [assessmentData, setAssessmentData] = useState<any[]>([]);
  const brandDetails = useBrandSettings();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!assessmentDetails?.id) return;

    const fetchAssessmentData = async () => {
      setLoading(true);
      try {
        const response = await getAssessmentList(medplum, assessmentDetails.id);
        const items = response?.data?.entry?.[0]?.resource?.item || [];
        setAssessmentData(items);
      } catch (error) {
        console.error('Error fetching assessment data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessmentData();
  }, [assessmentDetails?.id, medplum]);

  return (
    <Modal
      size="60%"
      opened={isOpen}
      onClose={handleClose}
      className="assessment-modal"
      closeButtonProps={{ style: { display: 'none' } }}
      overlayProps={{
        style: {
          opacity: 0.7,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(0.3px)',
          overflowBlock: 'hidden',
        },
      }}
    >
      <form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: 40,
          }}
        >
          <Text fw={700}>{assessmentDetails['Assessment Name']}</Text>
          <div
            style={{
              display: 'flex',
              gap: '15px',
              alignItems: 'center',
            }}
          >
            <div
              className="tw-mr-2 tw-text-[#344054] tw-border-[1px]  tw-font-medium tw-text-sm tw-font-['Inter'] tw-rounded-[60px] tw-px-4 tw-py-2"
              style={{ backgroundColor: brandDetails?.secondaryColor, color: '#000' }}
            >
              {assessmentDetails['Completion Date']}
            </div>

            <div className="tw-bg-[#ECFDF3] tw-border-[#ABEFC6] tw-border-[1px] tw-text-[#067647] tw-text-[12px] tw-rounded-[60px] tw-font-medium tw-uppercase tw-px-3 tw-py-2">
              {assessmentDetails.Status}
            </div>

            <Modal.CloseButton size={25} />
          </div>
        </div>
        <Modal.Body>
          <Box px="lg" py="sm" mt="lg">
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Loader color={brandDetails?.backgroundColor} />
              </div>
            ) : assessmentData.length === 0 ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Text className="text-center text-black mt-5 font-semibold">
                  The assessment for this patient has not been submitted.
                </Text>
              </div>
            ) : (
              assessmentData.map(
                (item, index) =>
                  item.text !== 'Disease Name' && (
                    <div
                      key={index}
                      style={{
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                        padding: '16px',
                        marginBottom: '16px',
                      }}
                    >
                      <Text fw={700} style={{ color: '#3673B6', marginBottom: '8px' }}>
                        Question No. {item.linkId}
                      </Text>
                      <Text fw={600} style={{ marginBottom: '5px' }}>
                        {item.text}
                      </Text>
                      <Text fw={600} style={{ marginBottom: '5px' }}>
                        {item?.answer?.[0]?.valueString
                          ? item.answer[0].valueString.charAt(0).toUpperCase() + item.answer[0].valueString.slice(1)
                          : item?.extension?.[0]?.valueString
                            ? item.extension[0].valueString.charAt(0).toUpperCase() +
                              item.extension[0].valueString.slice(1)
                            : ''}
                      </Text>
                    </div>
                  )
              )
            )}
          </Box>
        </Modal.Body>
      </form>
    </Modal>
  );
};

export default AssessmentDetailsModel;
