import React from 'react';
import { formatDate } from '../utils/util';

interface AssessmentProps {
  assessmentCompletionDate: string;
  assessmentInsightsSummaryData: string[] | undefined;
  hasData: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Assessment = (props: AssessmentProps) => {
  const { assessmentCompletionDate, assessmentInsightsSummaryData, hasData } = props;
  return (
    <div>
      {hasData && assessmentInsightsSummaryData && assessmentInsightsSummaryData?.length > 0 ? (
          <>
            <h4>Insights for Self assessment completed on {formatDate(assessmentCompletionDate)}</h4>
            <ul className="tab-content assessment_insights_tab">
              {assessmentInsightsSummaryData.map((line: string, index: number) => (
                <li key={index} style={{ paddingBottom: '5px' }}>
                  {line.replace(/^- /, '')}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p style={{ fontSize: '14px', fontWeight: 500, marginBottom: '8px' }}>No insights found for assessment.</p>
        )}
    </div>
  )
}

export default Assessment
