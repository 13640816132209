/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import BasicDetails from './BasicDetails';
import { useMedplum } from '@medplum/react';
import { Appointment, Patient } from '@medplum/fhirtypes';
import ContactInfo from './ContactInfo';
import AppointmentDetails from './AppointmentDetails';
import { getAppointment } from '../../../utils/CustomAPI';

interface PatientBasicDetailsProps {
  patientId?: string;
  patientResource?: Patient;
}

const PatientBasicDetails: React.FC<PatientBasicDetailsProps> = (props: PatientBasicDetailsProps) => {
  const medplum = useMedplum();
  const [appointmentsResource, setAppointmentsResource] = useState<Appointment[]>([]);
  

  const getAppointments = useCallback(() => {
    // Get appointments for the patient
    getAppointment(medplum, props?.patientId || '').then((res) => {
      setAppointmentsResource(res.data?.entry);
      
    }).catch((err) => {
      console.log(err);
    });
  }, [medplum, props?.patientId]);

  useEffect(() => {
    if (props?.patientId) {
      getAppointments();
    }
  }, [props?.patientId, getAppointments]);

  const memoizedBasicDetails = useMemo(() => (
    <BasicDetails patientResource={props?.patientResource}/>
  ), [props?.patientResource]);

  const memoizedContactInfo = useMemo(() => (
    <ContactInfo patientResource={props?.patientResource}/>
  ), [props?.patientResource]);

  const memoizedAppointmentDetails = useMemo(() => (
    <AppointmentDetails appointmentsResource={appointmentsResource}/>
  ), [appointmentsResource]);

  return (
    <>
      <div className='tw-flex tw-gap-4 xs:tw-flex-wrap sm:tw-flex-wrap md:tw-flex-wrap'>
        <div className='tw-w-2/4 xs:tw-w-[100%] sm:tw-w-[100%] md:tw-w-[100%]'>
          {memoizedBasicDetails}
          {memoizedContactInfo}
        </div>
        <div className='tw-w-2/4 xs:tw-w-[100%] sm:tw-w-[100%] md:tw-w-[100%]'>
          {memoizedAppointmentDetails}
        </div>
      </div>
    </>
  )
}

export default PatientBasicDetails