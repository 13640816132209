import React from 'react';
import { Container, Text, Title, Center } from '@mantine/core';

const NotFoundPage: React.FC = () => {
  return (
    <Container size="sm" style={{ textAlign: 'center' }}>
      <Center>
        <img src="../img/error-404.png" alt="Medplum Logo" style={{ width: '50%', marginTop: 20 }} />
      </Center>
      <Title order={1} mt="xl" size={32}>
        404: The page you are looking for isn’t here
      </Title>
      <Text color="dimmed" mt="md">
        You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.
      </Text>
    </Container>
  );
};

export default NotFoundPage;
