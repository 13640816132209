/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  Button,
  Group,
  AppShell as MantineAppShell,
  ScrollArea,
  Skeleton,
  Space,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { useMedplum, useMedplumNavigate } from '@medplum/react-hooks';
import { IconIndentDecrease, IconIndentIncrease, IconLogout, IconPlus } from '@tabler/icons-react';
import cx from 'clsx';
import { Fragment, MouseEventHandler, ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { BookmarkDialog } from '../BookmarkDialog/BookmarkDialog';
import { MedplumLink } from '../MedplumLink/MedplumLink';
import classes from './Navbar.module.css';
import { ContactPoint } from '@medplum/fhirtypes';
import { useBrandSettings } from '../../../app/src/BrandContext';

export interface NavbarLink {
  readonly icon?: JSX.Element;
  readonly label?: string;
  readonly href: string;
}

export interface NavbarMenu {
  readonly title?: string;
  readonly links?: NavbarLink[];
}

export interface NavbarProps {
  readonly pathname?: string;
  readonly searchParams?: URLSearchParams;
  readonly menus?: NavbarMenu[];
  readonly closeNavbar: () => void;
  readonly displayAddBookmark?: boolean;
  readonly resourceTypeSearchDisabled?: boolean;
  readonly logo: ReactNode;
  readonly navbarToggle: () => void;
}

export function Navbar(props: NavbarProps): JSX.Element {
  const navigate = useMedplumNavigate();
  const medplum = useMedplum();
  const brandDetails = useBrandSettings();
  const activeLink = getActiveLink(location.pathname, props.searchParams, props.menus);
  const [bookmarkDialogVisible, setBookmarkDialogVisible] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (brandDetails && Object.keys(brandDetails).length > 0) {
      setLoading(false);
    }
  }, [brandDetails]);

  const onLinkClick = (e: SyntheticEvent, to: string) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(to);
    if (window.innerWidth < 768) {
      props.closeNavbar();
    }
  };

  const navbarCollapse = () => {
    setNavbarOpen(!navbarOpen);
    props.navbarToggle();
  };

  return (
    <>
      <MantineAppShell.Navbar
        className="side_navbar"
        style={{
          backgroundColor: brandDetails?.sidebarColor || '#fff',
          width: navbarOpen ? '250px' : '70px',
          transition: navbarOpen ? '' : 'all 0.5s ease-in-out',
        }}
      >
        <style>{`
        ._linkActive_19mk3_37 svg {
          color: #fff !important;
        }
        ._link_19mk3_8 svg {
          color: ${brandDetails?.defaultTextColor || '#000'};
          position: relative;
          left: ${navbarOpen ? '0' : '4px'};
        }
        ._linkActive_19mk3_37 {
          color: #fff !important;
        }
      `}</style>
        <Group
          gap="xs"
          mt={20}
          style={{
            padding: `${navbarOpen ? '7px 10px' : '0'}`,
            justifyContent: `${navbarOpen ? 'space-between' : 'center'}`,
          }}
        >
          {navbarOpen && (
            <UnstyledButton style={{ padding: '0 8px' }} className={classes.logoButton} onClick={props.navbarToggle}>
              {loading ? (
                <Skeleton height={40} width={170} />
              ) : (
                <img
                  src={brandDetails?.brandLogo || '../img/Pragma_logo_Purple.svg'}
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.onerror = null;
                    target.src = '../img/Pragma_logo_Purple.svg';
                  }}
                  style={{ width: '150px' }}
                  alt="pragmaconnect Logo"
                />
              )}
            </UnstyledButton>
          )}
          {navbarOpen ? (
            <IconIndentDecrease
              color={brandDetails?.defaultTextColor || '#000'}
              onClick={navbarCollapse}
              style={{ cursor: 'pointer' }}
              size={25}
            />
          ) : (
            <IconIndentIncrease
              color={brandDetails?.defaultTextColor || '#000'}
              onClick={navbarCollapse}
              style={{ cursor: 'pointer' }}
              size={25}
            />
          )}
        </Group>
        <ScrollArea pb="xs">
          <MantineAppShell.Section grow pl="xs" pr="xs">
            {props.menus?.map((menu, index) => (
              <Fragment key={index}>
                <Text className={classes.menuTitle}>{menu.title}</Text>
                {menu.links?.map((link, index) => (
                  <div className="navbar-link-container" key={index} onClick={(e) => onLinkClick(e, link.href)}>
                    <NavbarLink
                      key={link.href}
                      to={link.href}
                      active={link.href === activeLink?.href}
                      onClick={(e) => onLinkClick(e, link.href)}
                    >
                      <NavLinkIcon to={link.href} icon={link.icon} />
                      {navbarOpen && <span>{link.label}</span>}
                    </NavbarLink>
                  </div>
                ))}
              </Fragment>
            ))}
            {props.displayAddBookmark && (
              <Button
                variant="subtle"
                size="xs"
                mt="xl"
                leftSection={<IconPlus size="0.75rem" />}
                onClick={() => setBookmarkDialogVisible(true)}
              >
                Add Bookmark
              </Button>
            )}
          </MantineAppShell.Section>
        </ScrollArea>
        <MantineAppShell.Section pl="xs" pr="xs" style={{ marginTop: '30px', position: 'fixed', bottom: '0' }}>
          <div className="navbar-link-bottom">
            <hr style={{ borderColor: '#EAECF0' }} />
            <NavbarLink
              to="#"
              active={false}
              onClick={async () => {
                await medplum.signOut();
                navigate('/signin');
              }}
            >
              <NavLinkIcon to="#" icon={<IconLogout />} />
              {navbarOpen && (
                <div>
                  <span>
                    {medplum.getProfile()?.name?.[0].given?.[0] + ' ' + medplum.getProfile()?.name?.[0].family}
                  </span>
                  <h5>
                    {medplum.getProfile()?.telecom?.find((item: ContactPoint) => item?.system === 'email')?.value || ''}
                  </h5>
                </div>
              )}
            </NavbarLink>
          </div>
        </MantineAppShell.Section>
      </MantineAppShell.Navbar>

      {props.pathname && props.searchParams && (
        <BookmarkDialog
          pathname={props.pathname}
          searchParams={props.searchParams}
          visible={bookmarkDialogVisible}
          onOk={() => setBookmarkDialogVisible(false)}
          onCancel={() => setBookmarkDialogVisible(false)}
        />
      )}
    </>
  );
}

interface NavbarLinkProps {
  readonly to: string;
  readonly active: boolean;
  readonly onClick: MouseEventHandler;
  readonly children: ReactNode;
}

function NavbarLink(props: NavbarLinkProps): JSX.Element {
  const brandDetails = useBrandSettings();
  return (
    <MedplumLink
      onClick={props.onClick}
      to={props.to}
      className={cx(classes.link, { [classes.linkActive]: props.active })}
      style={{
        backgroundColor: props.active ? brandDetails?.backgroundColor : '',
        color: props.active ? '#fff' : brandDetails?.defaultTextColor || '',
      }}
      onMouseEnter={(e: { currentTarget: { style: { backgroundColor: string; color: string } } }) => {
        e.currentTarget.style.backgroundColor = brandDetails?.backgroundColor || '';
        e.currentTarget.style.color = 'white';
      }}
      onMouseLeave={(e: {
        currentTarget: { style: { backgroundColor: string | undefined; color: string | undefined } };
      }) => {
        e.currentTarget.style.backgroundColor = props.active ? brandDetails?.backgroundColor : '';
        e.currentTarget.style.color = brandDetails?.defaultTextColor;
      }}
    >
      {props.children}
    </MedplumLink>
  );
}

interface NavLinkIconProps {
  readonly to: string;
  readonly icon?: JSX.Element;
}

function NavLinkIcon(props: NavLinkIconProps): JSX.Element {
  if (props.icon) {
    return props.icon;
  }
  return <Space w={30} />;
}

/**
 * Returns the best "active" link for the menu.
 * In most cases, the navbar links are simple, and an exact match can determine which link is active.
 * However, we ignore some search parameters to support pagination.
 * But we cannot ignore all search parameters, to support separate links based on search filters.
 * So in the end, we use a simple scoring system based on the number of matching query search params.
 * @param currentPathname - The web browser current pathname.
 * @param currentSearchParams - The web browser current search parameters.
 * @param menus - Collection of navbar menus and links.
 * @returns The active link if one is found.
 */
function getActiveLink(
  currentPathname: string | undefined,
  currentSearchParams: URLSearchParams | undefined,
  menus: NavbarMenu[] | undefined
): NavbarLink | undefined {
  if (!currentPathname || !currentSearchParams || !menus) {
    return undefined;
  }

  let bestLink = undefined;
  let bestScore = 0;

  for (const menu of menus) {
    if (menu.links) {
      for (const link of menu.links) {
        const score = getLinkScore(currentPathname, currentSearchParams, link.href);
        if (score > bestScore) {
          bestScore = score;
          bestLink = link;
        }
      }
    }
  }

  return bestLink;
}

/**
 * Calculates a score for a link.
 * Zero means "does not match at all".
 * One means "matches the pathname only".
 * Additional increases for each matching search parameter.
 * Ignores pagination parameters "_count" and "_offset".
 * @param currentPathname - The web browser current pathname.
 * @param currentSearchParams - The web browser current search parameters.
 * @param linkHref - A candidate link href.
 * @returns The link score.
 */
function getLinkScore(currentPathname: string, currentSearchParams: URLSearchParams, linkHref: string): number {
  const linkUrl = new URL(linkHref, 'https://example.com');
  if (currentPathname !== linkUrl.pathname) {
    return 0;
  }
  const ignoredParams = ['_count', '_offset'];
  for (const [key, value] of linkUrl.searchParams.entries()) {
    if (ignoredParams.includes(key)) {
      continue;
    }
    if (currentSearchParams.get(key) !== value) {
      return 0;
    }
  }
  let count = 1;
  for (const [key, value] of currentSearchParams.entries()) {
    if (ignoredParams.includes(key)) {
      continue;
    }
    if (linkUrl.searchParams.get(key) === value) {
      count++;
    }
  }
  return count;
}
