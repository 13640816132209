import React from "react";
import { Badge } from "@mantine/core";

interface StatusBadgeProps {
    statusColor: string;
    statusLabel: string;
    badgeColor?: string;
    textColor?: string;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({
    statusColor,
    statusLabel,
    textColor = "#333333",
}) => {
    return (
        <Badge
            variant="light"
            color={textColor}
            mih={25}
            style={{ display: 'flex', alignItems: 'center', border: `1px solid ${textColor}`, textTransform: 'capitalize', fontWeight: 500 }}
            radius="lg"
        >
            <span
                style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: statusColor,
                    marginRight: '8px',
                }}
            ></span>
            {statusLabel}
        </Badge>
    );
};
