/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useMemo, useState } from 'react';
import GroupButtons from '../../components/GroupButtons';
import { useMedplum } from '@medplum/react';
import { PatientInfo } from './PatientInfo';

const Record = () => {
  const medplum = useMedplum();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [resourceData, setResourceData] = useState<any[]>([]);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const buttonName: any = [
    { id: 1, name: 'Diagnosis Reports'},
    { id: 2, name: 'Prescriptions'},
    { id: 3, name: 'Invoices'}
  ]

  const buttonHandleClick = (data: {id: number, name: string}) => {
    setActiveTab(data.id);
  }

  const getResourceType = () => {
    const resourceType: Record<number, string> = {
      1: 'DiagnosticReport',
      2: 'MedicationDispense',
      3: 'Invoice',
    };

    return resourceType[activeTab];
  };

  useEffect(() => {
    const offset = 0;
    setResourceData([]);
    getAPIResource(offset);
  }, [activeTab]);

  const getAPIResource = (_offset: number) => {
    setIsloading(true);
    const resourceType = getResourceType();
    medplum
    .search(
      resourceType as
        | 'DiagnosticReport'
        | 'MedicationDispense'
        | 'Invoice',
      {
        _count: 10,
        _offset: _offset, // Add your offset value here
        _sort: '-_lastUpdated',
        _total: 'accurate',
        patient: `Patient/${ medplum.getProfile()?.id}`,
      }
    )
    .then((res: any) => {
      setResourceData(res);
      setIsloading(false);
    })
    .catch((err: any) => {
      console.error(err);
      setResourceData([]);
    });
  }

  const getOffsetValue = (updatedOffset: number) => {
    getAPIResource(updatedOffset);
  }

  const memoizedPatientInfo = useMemo(
    () => (
      <PatientInfo
        activeTab={activeTab}
        resourceData={resourceData}
        isLoading={isLoading}
        offsetValue={(offset) => getOffsetValue(offset)}
      />
    ),
    [activeTab, resourceData]
  );

  return (
    <>
      <div className='tw-border tw-border-[#EAEyCF0] tw-rounded-[12px] tw-mb-6 tw-mt-6'>
          <div className='tw-px-4 tw-pt-4'>
            <h5 className='tw-text-[18px] xs:tw-text-[18px] tw-font-semibold tw-tracking-tight tw-text-[#101828]'>Reports & Billing</h5>
          </div>
          <hr className="h-px tw-my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
          <div className='tw-flex tw-justify-between tw-px-4 tw-py-4'>
            <GroupButtons value={buttonName} buttonHandleClick={(data)  => buttonHandleClick(data)}/>
          </div>
          <div>
            {memoizedPatientInfo}
          </div>
      </div>
    </>
  )
}

export default Record;
