/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QuestionnaireResponse } from "@medplum/fhirtypes";

export function createQuestionnaireResponse (answer?: any, questionnaireId?: string, patientId?: string, patientName?: string): QuestionnaireResponse {
  const questionnaireResponse: QuestionnaireResponse = {
    resourceType: 'QuestionnaireResponse',
    status: 'completed',
    authored: new Date().toISOString(),
    questionnaire: `Questionnaire/${questionnaireId}`,
    item: answer,
    source: { reference: `Patient/${patientId}`, display: patientName },
    subject: { reference: `Patient/${patientId}`, display: patientName }
  }

  return questionnaireResponse;
}