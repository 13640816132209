/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';

const carouselContent = [
  {
    heading: 'Instant Access',
    description: "Quick login to your personalized health dashboard.",
  },
  {
    heading: 'Trusted Security',
    description: 'HIPAA-compliant, top-level data protection.',
  },
  {
    heading: 'Personalized Insights',
    description: 'View appointments, records, and labs in one place.',
  },
  {
    heading: 'AI-Driven Recommendations',
    description: 'Get personalized health insights instantly after login.',
  },
  // Add more content as needed
];

const SignInCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto slide the carousel
  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 5000); // 3 seconds interval

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  // Function to handle next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === carouselContent.length - 1 ? 0 : prevIndex + 1));
  };

  // Function to handle previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? carouselContent.length - 1 : prevIndex - 1));
  };
  return (
    <>
      <div className="tw-relative signin_bg" style={{ backgroundImage: 'url(../img/signin-bg.png)'}}>
        {/* Static Image */}
        {/* Text Overlay */}
        <div className="tw-absolute tw-bottom-0 tw-w-full tw-bg-black tw-bg-opacity-50 tw-backdrop-blur-lg tw-text-white tw-py-4 tw-px-6 tw-rounded-b-[20px] tw-h-[22%]">
          <p className="tw-text-center tw-font-semibold tw-text-[20px]">{carouselContent[currentIndex].heading}</p>
          <p className="tw-text-center tw-text-sm tw-text-[#E2D8EC]">{carouselContent[currentIndex].description}</p>
        </div>

        {/* Carousel Navigation */}
      <div className="tw-absolute tw-bottom-0 tw-w-full tw-flex tw-justify-around tw-px-4">
        <button onClick={prevSlide} className="tw-text-[20px] tw-p-2 tw-text-white hover:tw-text-gray-400 tw-cursor-pointer">
          &#10094; {/* Left arrow */}
        </button>
        <button onClick={nextSlide} className="tw-text-[20px] tw-p-2 tw-text-white hover:tw-text-gray-400">
          &#10095; {/* Right arrow */}
        </button>
      </div>

        {/* Carousel Dots */}
        <div className="tw-absolute tw-bottom-[20px] tw-w-full tw-flex tw-justify-center tw-space-x-2">
          {carouselContent.map((_, idx) => (
            <span
              key={idx}
              className={`tw-w-2 tw-h-2 tw-rounded-full ${
                idx === currentIndex ? 'tw-bg-white' : 'tw-bg-white tw-bg-opacity-50'
              }`}
            ></span>
          ))}
        </div>
      </div>
    </>
  );
};

export default SignInCarousel;
